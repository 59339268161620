import React, { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useFormik } from 'formik';
import { MultiSelect } from 'primereact/multiselect';
import { Badge } from 'primereact/badge';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';
import * as Yup from 'yup';

// Constants
import { NUMBER_OF_INTERVIEWERS } from '../../constants/dropdownOptions';
import {
  BUTTON_TYPES,
  Interview_Invitations_Status,
  InterviewType,
  LABEL_TYPES
} from '../../constants/common';
import { formatAvailabilityTime, wordCapitalize } from '../../utils/common';

// Components
import Label from '../Label';
import { allInterviewers } from '../../redux/actions/showOptions';
import CustomButton from '../CustomButton';
import { editInterviewRoundAction } from '../../redux/actions/interviews';

export default function AssignToOtherModal(props) {
  const dispatch = useDispatch();
  const { onHide, interviewroundData, show } = props;

  // State
  const [interviewerOptions, setInterviewerOptions] = useState(null);

  // Store
  const { options } = useSelector((state) => state);

  const interviewersOptions = (options) => {
    if (!options?.length) return [];
    return options?.map((opt) => {
      return {
        ...opt,
        name: `${opt.FirstName} ${opt.LastName}`,
        code: `${opt.ID}`,
        key: `${opt.ID}`,
        isAvailable: opt.IsAvailable
      };
    });
  };

  useEffect(() => {
    const modifiedInterviewerOptions = interviewersOptions(options);
    setInterviewerOptions(modifiedInterviewerOptions);
  }, [options]);

  useEffect(() => {
    dispatch(
      allInterviewers(
        interviewroundData?.StartDateOfInterviewRound,
        interviewroundData?.EndDateOfInterviewRound,
        '',
        interviewroundData?.ID || '',
        interviewroundData?.InterviewRound === InterviewType['HR Round'],
        interviewroundData?.InterviewRound === InterviewType.Practical
      )
    );
  }, [dispatch]);

  const formik = useFormik({
    initialValues: {
      interviewerID: interviewroundData?.InterviewRoundInvitations?.length
        ? interviewroundData?.InterviewRoundInvitations.filter(
            (invite) => invite.Status === Interview_Invitations_Status.Pending
          ).map((interviewer) => interviewer?.Invited?.ID)
        : [],
      interviewerLimit: interviewroundData?.InterviewerLimit.toString() || NUMBER_OF_INTERVIEWERS[0]
    },
    validationSchema: Yup.object({
      interviewerID: Yup.array()
        .min(1, 'Interviewer is required.')
        .required('Interviewer is required.'),
      interviewerLimit: Yup.number()
        .min(1, 'Minimum Limit 1')
        .max(10, 'Maximum  Limit 10')
        .typeError('Only numbers are allowed.')
        .required('Interviewer Limit is required.')
    }),
    onSubmit: (values) => {
      try {
        let formData = {
          interviewerLimit: values.interviewerLimit,
          interviewRoundMode: interviewroundData?.InterviewRoundMode,
          interviewStartDateTime: interviewroundData?.StartDateOfInterviewRound,
          interviewEndDateTime: interviewroundData?.EndDateOfInterviewRound,
          ...(values.interviewerID?.length && {
            interviewerID: values.interviewerID?.length ? values.interviewerID.join(',') : ''
          })
        };

        let formdataJson = JSON.stringify(formData);

        dispatch(
          editInterviewRoundAction({
            formdataJson,
            interviewRoundID: interviewroundData?.ID
          })
        );
      } catch (error) {
        console.log(error);
      }
    },
    enableReinitialize: true
  });

  const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
  };

  const interviewerTemplate = (option) => {
    return (
      <>
        <ReactTooltip />
        <div data-tip={`${formatAvailabilityTime(option.InterviewerToAvailability)}`}>
          {!option.isAvailable ? (
            <span className="unavailable-interviewer-text-color mr-1">
              {wordCapitalize(option.name)}
            </span>
          ) : (
            <span className="mr-1">{wordCapitalize(option.name)}</span>
          )}
          {!!option?.UMap?.length && (
            <span className={`${!option.isAvailable && 'unavailable-interviewer-text-color'} mr-1`}>
              [{option?.UMap?.map((tech) => wordCapitalize(tech?.MapT?.Technology)).join(', ')}]
            </span>
          )}
          {!option.isAvailable && (
            <>
              {formatAvailabilityTime(option.InterviewerToAvailability) && (
                <span className="unavailable-interviewer-text-color mr-1">{`[${formatAvailabilityTime(
                  option.InterviewerToAvailability
                )}]`}</span>
              )}
              {/* <i
                className="pi pi-exclamation-triangle unavailable-interviewer-text-color"
                data-tip="Might not be available for the above schedule selected"
              /> */}
            </>
          )}
          {!!option?.IsPracticalAdmin && <Badge value="Practical Admin" severity="success"></Badge>}
        </div>
      </>
    );
  };

  return (
    <Dialog header="Assign interview to other interviewer" visible={show} onHide={onHide}>
      <form
        onSubmit={formik.handleSubmit}
        style={{ width: '600px' }}
        className="p-fluid"
        autoComplete="off"
        encType="multipart/form-data">
        <div className="form-row-wrapper">
          <div className="form-col full-width">
            <div className="custom-form-group">
              <Label
                htmlFor="interviewerLimit"
                text={LABEL_TYPES.INTERVIEWER_LIMIT}
                isChildren
                isMandatory
                tooltip="Sets the maximum no. of interviewers can join the interview."
              />
              <span className="p-input-icon-right">
                <Dropdown
                  id="interviewerLimit"
                  name="interviewerLimit"
                  value={formik.values.interviewerLimit}
                  options={NUMBER_OF_INTERVIEWERS}
                  onChange={formik.handleChange}
                  placeholder="Select Number of Interviewers"
                  panelClassName="custom-dropdown-panel"
                />
              </span>
              {getFormErrorMessage('interviewerLimit')}
            </div>
          </div>
          <div className="form-col full-width">
            <div className="custom-form-group">
              <Label htmlFor="interviewerIDs" text={LABEL_TYPES.INTERVIEWERS} isMandatory />
              <span className="p-input-icon-right">
                <MultiSelect
                  filter
                  name="interviewerID"
                  id="interviewerID"
                  tabIndex="12"
                  optionLabel="name"
                  optionValue="code"
                  value={formik.values.interviewerID}
                  onChange={formik.handleChange}
                  options={interviewerOptions}
                  itemTemplate={interviewerTemplate}
                  placeholder="Select Interviewer"
                  display="chip"
                  className={classNames({
                    'p-invalid': isFormFieldValid('interviewerID')
                  })}
                />
              </span>
              {getFormErrorMessage('interviewerID')}
            </div>
          </div>
        </div>
        <div className="form-btn-wrapper justify-flex-end">
          <CustomButton
            variant="contained"
            onClick={() => {
              formik.resetForm();
              onHide();
            }}
            color="error"
            className="mb-3 w-auto gray-btn">
            {BUTTON_TYPES.CANCEL}
          </CustomButton>
          <CustomButton type="submit" variant="contained" className="mb-3 w-auto">
            {BUTTON_TYPES.SAVE}
          </CustomButton>
        </div>
      </form>
    </Dialog>
  );
}
