import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';

// Components
import DataTable from '../../components/DataTable';
import UserDetails from '../../components/popup/UserDetails';
import { ActionButtons, ResumeButton } from '../../components/ActionButtons';
import AddEditCandidates from './AddEditCandidates';

// Prime-React Components
import { confirmDialog } from 'primereact/confirmdialog';

// Constants
import {
  ACTION_TYPE,
  CANDIDATE_SOURCE_TYPE,
  DEBOUNCE_TIMEOUT,
  INITIAL_PAGE_NUMBER,
  INITIAL_SIZE,
  TABLE_NAME
} from '../../constants/common';
import { CANDIDATE_COLUMNS } from '../../constants/tableColumns';

// Redux-Actions
import {
  deleteCandidate,
  getCandidateDetails,
  getCandidateList,
  getCandidateNote
} from '../../redux/actions/candidates';
import { candidateDetailsAction } from '../../redux/actions/userDetails';

// Utils
import { debounce } from '../../utils/debounce';
import {
  downloadPdf,
  formatExperience,
  isDarkMode,
  normalizeObject,
  openResumeLink,
  renderHrAvatar,
  toLowerCaseText,
  wordCapitalize
} from '../../utils/common';

// Apis
import { filteredHrListApi } from '../../redux/apis/hr';
import Notes from '../../components/popup/Notes';
import ReactTooltip from 'react-tooltip';
import SendJobDescription from '../../components/popup/SendJobDescription';

const pathBase = `${process.env.REACT_APP_API_ENDPOINT}/public/uploads/resume`;

const candidateTableStates = {
  searchValue: '',
  pageNumber: INITIAL_PAGE_NUMBER,
  size: INITIAL_SIZE,
  sortBy: 'DateModified',
  orderBy: 'desc',
  minExp: '',
  maxExp: '',
  technology: '',
  filterByHR: ''
};

const Candidates = () => {
  const dispatch = useDispatch();
  const technologyListData = useSelector((state) => state.hrs.interviewTechnologyType);
  const [editCandidateId, setEditCandidateId] = useState(null);
  const { candidates, userDetails } = useSelector((state) => state);
  const [candidate, setCandidate] = useState([]);
  const [candidateForNote, setCandidateForNote] = useState([]);
  const [candidateExport, setCandidateExport] = useState([]);
  const [openSelectJDModal, setOpenSelectJDModal] = useState(false);
  const [selectedCandidateId, setSelectedCandidateId] = useState(null);
  const [hrs, setHrs] = useState([]);
  const [tableStates, setTableStates] = useState(candidateTableStates);
  const [candidateId, setCandidateId] = useState(null);
  const [candidateDetails, setCandidateDetails] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [showNotesPopup, setShowNotesPopup] = useState(false);

  const onTogglePopup = () => setShowPopup((prevState) => !prevState);
  const onToggleNotePopup = () => setShowNotesPopup((prevState) => !prevState);

  const [visibleRight, setVisibleRight] = useState(false);
  const onToggleSiderbar = () => setVisibleRight(!visibleRight);

  // Experience Year Filter
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    dispatch(getCandidateList(tableStates));
  }, [dispatch, tableStates, candidates?.isDeleted, candidates?.isUpdated, candidates?.isAdded]);

  useEffect(() => {
    const modifiedCandidate = candidates?.data?.map((candidateList) => {
      return {
        ...candidateList,
        CandidateName: (
          <Link
            to="#"
            className="table-view-popup-link"
            onClick={() => handleView(candidateList?.ID)}
            title={`Mobile : ${candidateList.Mobile || '--'}`}>
            {`${wordCapitalize(candidateList.FirstName)} ${wordCapitalize(candidateList.LastName)}`}
          </Link>
        ),
        Technology: (
          <ul className="technology-list">
            {candidateList.UMap && candidateList.UMap.length > 0
              ? candidateList.UMap?.map((tech) => (
                  <li
                    key={tech.MapT.ID}
                    style={{
                      backgroundColor: `${
                        isDarkMode() ? tech?.MapT?.DarkModeColor : tech?.MapT?.TechnologyColor
                      }`
                    }}
                    className="technology-badge">
                    {tech.MapT.Technology}
                  </li>
                ))
              : []}
          </ul>
        ),
        Experience: formatExperience(
          candidateList.YearsOfExperience,
          candidateList?.MonthsOfExperience
        ),
        Mobile: (
          <div className="center">
            <span>{candidateList.Mobile || '--'}</span>
            {candidateList.Mobile && (
              <>
                <i
                  data-tip="Copied"
                  data-event="click focus"
                  data-for={`copyIcon_${candidateList.Mobile}`}
                  id={`copyIcon_${candidateList.Mobile}`}
                  className="pi pi-fw pi-copy ml-1 copy-icon"
                />
                <ReactTooltip
                  id={`copyIcon_${candidateList.Mobile}`}
                  globalEventOff="click"
                  afterShow={() => {
                    navigator.clipboard.writeText(candidateList.Mobile);
                    setTimeout(() => {
                      ReactTooltip.hide();
                    }, 500);
                  }}
                />
              </>
            )}
          </div>
        ),
        Resume: (
          <div className="flex">
            {candidateList?.ResumeOfCandidate && (
              <>
                <ResumeButton
                  onClick={() => {
                    openResumeLink(
                      `${pathBase}/${candidateList?.ID}/${candidateList?.ResumeOfCandidate?.FilePath}`
                    );
                  }}
                />
                <i
                  data-tip="Download"
                  onClick={() =>
                    downloadPdf(
                      pathBase,
                      candidateList?.ID,
                      candidateList?.ResumeOfCandidate?.FilePath
                    )
                  }
                  className="pi pi-fw pi-download"
                />
              </>
            )}
          </div>
        ),
        HR: renderHrAvatar(
          candidateList?.ReportsToUser?.ID,
          candidateList?.ReportsToUser?.UserProfile,
          candidateList?.ReportsToUser?.FirstName,
          candidateList?.ReportsToUser?.LastName
        ),
        Location: candidateList?.Address || '--',
        Company: candidateList?.CurrentCompany || '--',
        action: (
          <div className="flex align-items-center">
            <ActionButtons
              notes
              sendJD
              delete
              onClick={(actionType) => handleRowActions(actionType, candidateList?.ID)}
            />
          </div>
        )
      };
    });
    setCandidate(modifiedCandidate);
  }, [candidates, isDarkMode()]);

  useEffect(() => {
    const modifiedCandidate = candidates?.data?.map((candidateList) => {
      const name = `${wordCapitalize(candidateList.FirstName)} ${wordCapitalize(
        candidateList.LastName
      )}`;
      return {
        CandidateName: name,
        Technology: (
          <ul className="technology-list">
            {candidateList.UMap && candidateList.UMap.length > 0
              ? candidateList.UMap?.map((tech) => (
                  <li
                    key={tech.MapT.ID}
                    style={{
                      backgroundColor: `${
                        isDarkMode() ? tech?.MapT?.DarkModeColor : tech?.MapT?.TechnologyColor
                      }`
                    }}
                    className={
                      `${toLowerCaseText(tech.MapT.Technology.replace(/([^\w]+|\s+)/g, ''))}` +
                      '-badge technology-badge'
                    }>
                    {tech.MapT.Technology}
                  </li>
                ))
              : []}
          </ul>
        ),
        Experience: formatExperience(
          candidateList.YearsOfExperience,
          candidateList?.MonthsOfExperience
        ),
        Mobile: (
          <div className="center">
            <span>{candidateList.Mobile || '--'}</span>
            {candidateList.Mobile && (
              <>
                <i
                  data-tip="Copied"
                  data-event="click focus"
                  data-for={`copyIcon_${candidateList.Mobile}`}
                  id={`copyIcon_${candidateList.Mobile}`}
                  className="pi pi-fw pi-copy ml-1 copy-icon"
                />
                <ReactTooltip
                  id={`copyIcon_${candidateList.Mobile}`}
                  globalEventOff="click"
                  afterShow={() => {
                    navigator.clipboard.writeText(candidateList.Mobile);
                    setTimeout(() => {
                      ReactTooltip.hide();
                    }, 500);
                  }}
                />
              </>
            )}
          </div>
        )
      };
    });
    setCandidateExport(modifiedCandidate);
  }, [candidates, isDarkMode()]);

  useEffect(() => {
    candidateId && dispatch(candidateDetailsAction(candidateId));
  }, [dispatch, candidateId, userDetails?.isCandidateUpdated]);

  useEffect(() => {
    filteredHrListApi().then((res) => {
      if (res?.data?.data?.length) {
        let hrs = res.data.data.map((hr) => ({
          name: (hr?.FirstName || '') + ' ' + (hr?.LastName || ''),
          id: hr?.ID
        }));
        setHrs(hrs);
      }
    });
  }, []);

  const modifyUserDetails = useCallback(() => {
    if (userDetails?.ID) {
      // Display -- if the candidate mobile number is not provided.
      let mobileValue = '--';
      if (userDetails?.Mobile) {
        mobileValue = userDetails?.Mobile;
      }
      if (mobileValue && mobileValue === 'null') {
        mobileValue = '--';
      }
      const candidateDetailsData = [
        {
          title: 'Name',
          value: `${wordCapitalize(userDetails?.FirstName) || ''} 
              ${wordCapitalize(userDetails?.LastName) || ''}`
        },
        { title: 'Gender', value: userDetails?.Gender === 1 ? 'Male' : 'Female' || '' },
        {
          title: 'Experience',
          value: formatExperience(userDetails?.YearsOfExperience, userDetails?.MonthsOfExperience)
        },
        {
          title: 'Email Address',
          value: (
            <div className="center">
              <span>{userDetails?.Email || '--'}</span>
              {userDetails?.Email && (
                <>
                  <i
                    id={userDetails?.ID}
                    data-tip="Copied"
                    data-event="click focus"
                    data-for={userDetails?.ID}
                    className="pi pi-fw pi-copy ml-1 copy-icon"
                  />
                  <ReactTooltip
                    id={userDetails?.ID}
                    globalEventOff="click"
                    afterShow={() => {
                      navigator.clipboard.writeText(userDetails?.Email);
                      setTimeout(() => {
                        ReactTooltip.hide();
                      }, 500);
                    }}
                  />
                </>
              )}
            </div>
          )
        },
        {
          title: 'Mobile',
          value: (
            <div className="center">
              <span>{mobileValue || '--'}</span>
              {userDetails?.Mobile && (
                <>
                  <i
                    id={mobileValue}
                    data-tip="Copied"
                    data-event="click focus"
                    data-for={mobileValue}
                    className="pi pi-fw pi-copy ml-1 copy-icon"
                  />
                  <ReactTooltip
                    id={mobileValue}
                    globalEventOff="click"
                    afterShow={() => {
                      navigator.clipboard.writeText(mobileValue);
                      setTimeout(() => {
                        ReactTooltip.hide();
                      }, 500);
                    }}
                  />
                </>
              )}
            </div>
          )
        },
        {
          title: 'Second Mobile',
          value: (
            <div className="center">
              <span>{userDetails?.SecondaryMobile || '--'}</span>
              {userDetails?.SecondaryMobile && (
                <>
                  <i
                    id={userDetails?.SecondaryMobile}
                    data-tip="Copied"
                    data-event="click focus"
                    data-for={userDetails?.SecondaryMobile}
                    className="pi pi-fw pi-copy ml-1 copy-icon"
                  />
                  <ReactTooltip
                    id={userDetails?.SecondaryMobile}
                    globalEventOff="click"
                    afterShow={() => {
                      navigator.clipboard.writeText(userDetails?.SecondaryMobile);
                      setTimeout(() => {
                        ReactTooltip.hide();
                      }, 500);
                    }}
                  />
                </>
              )}
            </div>
          )
        },
        {
          title: 'Current Company',
          value: userDetails?.CurrentCompany || ''
        },
        {
          title: 'Technology',
          value:
            userDetails.CandidateTechnology && userDetails.CandidateTechnology.length > 0
              ? userDetails.CandidateTechnology?.map((tech) => tech.Technology).join(', ')
              : ''
        },
        {
          title: 'Current CTC',
          value: userDetails?.CurrentCTC ? `${userDetails?.CurrentCTC} LPA` : ''
        },
        {
          title: 'Candidate Location',
          value: userDetails?.Address || ''
        },
        {
          title: 'Candidate Source',
          value: CANDIDATE_SOURCE_TYPE.find((type) => type.code === userDetails?.CandidateSource)
            ?.name
        }
      ];
      // Only add the candidate reference name if candidate provided source is reference.
      if (
        userDetails?.CandidateSource ===
        CANDIDATE_SOURCE_TYPE[CANDIDATE_SOURCE_TYPE.length - 1].code
      ) {
        candidateDetailsData.push({
          title: 'Employee Ref Name',
          value: userDetails?.EmployeeReferenceName || ''
        });
      }
      candidateDetailsData.push(
        {
          title: 'Created By',
          value: renderHrAvatar(
            userDetails?.ReportsToUser?.ID,
            userDetails?.ReportsToUser?.UserProfile,
            userDetails?.ReportsToUser?.FirstName,
            userDetails?.ReportsToUser?.LastName,
            true
          )
        },
        {
          title: 'Last Updated Time',
          value: userDetails?.DateModified ? moment(userDetails?.DateModified).format('lll') : null
        }
      );
      setCandidateDetails(candidateDetailsData);
    }
  }, [userDetails]);

  useEffect(() => {
    modifyUserDetails();
  }, [userDetails, modifyUserDetails]);

  const handlePagination = (pageNumber, size) =>
    setTableStates((prevState) => {
      return {
        ...prevState,
        pageNumber,
        size
      };
    });

  const handleSearch = (searchValue) => {
    setTableStates((prevState) => {
      return {
        ...prevState,
        searchValue
      };
    });
  };

  const handleSort = (sortBy) => {
    setTableStates((prevState) => {
      return {
        ...prevState,
        sortBy,
        orderBy: prevState.orderBy === 'asc' ? 'desc' : 'asc'
      };
    });
  };

  const handleFilterByHr = (filterByHR) => {
    setTableStates((prevState) => {
      return {
        ...prevState,
        filterByHR
      };
    });
  };

  const handleYearExperienceFilter = (minExp, maxExp) => {
    setTableStates((prevState) => {
      return {
        ...prevState,
        minExp,
        maxExp
      };
    });
    handleClose();
  };

  const handleView = (ID) => {
    if (ID) {
      onTogglePopup();
      setCandidateId(ID);
    }
  };

  const handleRowActions = (actionType, rowId) => {
    if (actionType === ACTION_TYPE.VIEW) {
      onTogglePopup();
      setCandidateId(rowId);
    } else if (actionType === ACTION_TYPE.EDIT) {
      setEditCandidateId(rowId);
      dispatch(getCandidateDetails(rowId));
      document.body.classList.add('sidebar-open');
      onToggleSiderbar();
    } else if (actionType === ACTION_TYPE.NOTES) {
      dispatch(getCandidateNote(rowId));
      setCandidateForNote(rowId);
      onToggleNotePopup();
    } else if (actionType === ACTION_TYPE.DELETE) {
      confirmDialog({
        header: 'Delete',
        message: 'Are you sure you want to delete?',
        icon: 'pi pi-exclamation-triangle',
        accept: () => dispatch(deleteCandidate(rowId))
      });
    } else if (actionType === ACTION_TYPE.SEND_JD) {
      setSelectedCandidateId(rowId);
      setOpenSelectJDModal(true);
    }
  };

  const handleAdd = () => {
    setEditCandidateId(null);
    setVisibleRight(true);
    document.body.classList.add('sidebar-open');
  };

  const handleStatusFilter = (value) => {
    setTableStates((prevState) => {
      return {
        ...prevState,
        technology: value || ''
      };
    });
  };

  return (
    <>
      <UserDetails
        header="Candidate Details"
        data={candidateDetails}
        onHide={onTogglePopup}
        show={showPopup}
      />
      {showNotesPopup && (
        <Notes
          onHide={onToggleNotePopup}
          candidateId={candidateForNote}
          data={candidates.candidateNotes}
          show={showNotesPopup}
        />
      )}
      {openSelectJDModal && (
        <SendJobDescription
          show={openSelectJDModal}
          onHide={() => setOpenSelectJDModal(false)}
          candidateId={selectedCandidateId}
        />
      )}
      <AddEditCandidates
        ID={editCandidateId}
        onHide={onToggleSiderbar}
        show={visibleRight}
        handleView={handleView}
      />
      <DataTable
        name={TABLE_NAME.CANDIDATE}
        searchPlaceHolder="Search by Candidate name, Location and Company"
        totalRecords={candidates.pagination.totalRecords}
        onPagination={handlePagination}
        onSearch={debounce(handleSearch, DEBOUNCE_TIMEOUT)}
        onSort={handleSort}
        onAdd={() => handleAdd()}
        data={candidate}
        hrFilter
        hrs={hrs}
        onFilter={handleFilterByHr}
        selectedHrs={tableStates.filterByHR}
        exportData={candidateExport}
        columns={CANDIDATE_COLUMNS}
        experienceFilter
        open={open}
        label={
          tableStates.minExp && tableStates.maxExp
            ? `${tableStates.minExp} - ${tableStates.maxExp} Years`
            : 'Years Exp filter'
        }
        onExperienceFilter={handleClick}
        minExp={tableStates.minExp}
        maxExp={tableStates.maxExp}
        handleYearExperienceFilter={handleYearExperienceFilter}
        handleClose={handleClose}
        anchorEl={anchorEl}
        isDropdownMultiSelect
        dropdownPlaceHolder="Select Technology"
        onStatusFilter={handleStatusFilter}
        dropdown={
          technologyListData?.length
            ? technologyListData.map((tech) => ({ name: tech.Technology, value: tech.ID }))
            : []
        }
        dropdownValue={tableStates.technology}
        clearFilter
        isClearFilterDisabled={
          JSON.stringify(normalizeObject(candidateTableStates)) ===
          JSON.stringify(normalizeObject(tableStates))
        }
        clearFilterOnClick={() => setTableStates(candidateTableStates)}
      />
    </>
  );
};

export default Candidates;
